<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-row>
        <b-col
          md="12"
          class="mb-3 text-right"
        >
          <b-button
            class=""
            variant="outline-primarySpore"
            @click="showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            <span class="align-middle" v-if="!show"> Mostrar filtros </span>
            <span class="align-middle" v-else > Ocultar filtros </span>
          </b-button>
        </b-col>
      </b-row>
      <b-card no-body v-if="show">
        <b-card-header class="pb-50">
          <h5>
            Filtrar
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              xl="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Número de Contrato"
              >
                <b-form-input
                id="contract"
                v-model="filters.contract"
                @update="textTransform($event, 'contract')"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Número Medidor"
              >
                <b-form-input
                id="meterNumber"
                v-model="filters.meterNumber"
                @update="textTransform($event, 'meterNumber')"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Serie Spore"
              >
                <b-form-input
                id="serie"
                v-model="filters.serie"
                @update="textTransform($event, 'serie')"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Serie de tarjeta"
              >
                <b-form-input
                id="serieCard"
                v-model="filters.serieCard"
                @update="textTransform($event, 'serieCard')"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="mb-md-0 mb-2"
            >
            <b-form-group
                label="Estatus Instalación"
              >
                <b-form-select
                  id="installed"
                  v-model="filters.installed"
                  :options="[
                    { value: '1', text: 'Instalado' },
                    { value: '0', text: 'No instalado' }
                  ]"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="2"
              class="mb-md-0 mb-2"
            >
            <b-form-group
                label="Transmisión"
              >
                <b-form-select
                  id="transmissionStatus"
                  v-model="filters.transmissionStatus"
                  :options="transmitionStatusOptions"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              xl="10"
              class="text-right"
            >
              <b-form-group
                label="."
                style="color:#FFFFFF"
              >
                <b-button
                  variant="primarySpore"
                  @click="search"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  <span>Buscar</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="danger"
                  @click="clear"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Limpiar</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col md="6" class="mb-3 text-left">
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>{{ $t('Labels.Show') }}</label>
                <v-select v-model="params.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
                <label>{{ $t('Labels.Show') }}</label>
              </b-col>
            </b-col>
            <b-col md="6" class="mb-3 text-right">
              <b-button v-if="userRole !== 'S' && userRole !== 'I'" class="mr-1" variant="primarySpore" @click="goToRegisterMeter" v-b-tooltip.hover.top title="Registrar Medidor">
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
              <b-button class="" variant="primarySpore" @click="goToInstallMeter" v-b-tooltip.hover.top title="Instalar Medidor">
                <feather-icon icon="ToolIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <h4>Total de medidores: {{ totalRows }}</h4>
            </b-col>
            <b-col cols="12" md="4" class="text-right" v-if="totalRows !== 0">
              <a @click="getMetersExport">
                  <feather-icon icon="DownloadCloudIcon" size="20" />
                </a>
            </b-col>
          </b-row>
        </div>
        <div class="text-center" v-if="!this.response.length">
          No hay registros que mostrar
        </div>
        <b-table
          v-if="response.length"
          class="position-relative"
          hover
          responsive
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :items="response"
          :fields="tableColumns"
          no-local-sorting
          @sort-changed="handleSort"
        >
          <template #cell(contract)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.contract">
              <span class="align-text-top text-capitalize">{{ data.item.contract }}</span>
            </div>
          </template>
          <template #cell(meterNumber)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.meterNumber">
              <b-avatar
                v-if="data.item.meterNumber"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.meterNumber.replace(/^(0)+/, '') }}</span>
            </div>
          </template>
          <template #cell(serie)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.serie">
              <b-avatar
                v-if="data.item.serie"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.serie }}</span>
            </div>
          </template>
          <template #cell(serieCard)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.serieCard">
              <span class="align-text-top text-capitalize">{{ data.item.serieCard }}</span>
            </div>
          </template>
          <template #cell(energy)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/energy.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.energy || 0 }} KWh</span>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                size="32"
                class="avatar-border-2 box-shadow-1"
                :variant="data.item.transmissionStatusIndicator.color"
              >
                <feather-icon class="align-text-top" :icon="data.item.transmissionStatusIndicator.icon" size="18" />
              </b-avatar>
              <b>
                <span class="align-text-top ml-1" :class="`text-${data.item.transmissionStatusIndicator.color}`">
                  {{ data.item.transmissionStatusIndicator.message }}
                </span>
              </b>
            </div>
          </template>
          <template #cell(installedAt)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.installedAt">
              <span class="align-text-top text-capitalize">{{ data.item.installedAt }}</span>
            </div>
          </template>
          <template #cell(trasmitedAt)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.trasmitedAt">
              <b-avatar
                v-if="data.item.trasmitedAt"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ format(data.item.trasmitedAt) }}</span>
            </div>
          </template>
          <template #cell(relayState)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                size="32"
                class="avatar-border-2 box-shadow-1"
                :variant="data.item.relayState === '50' ? 'light-primary' : 'light-secondary'"
              >
              <feather-icon
                size="30"
                icon="PowerIcon"
              />
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.relayState === '50' ? 'Abierto' : 'Cerrado'  }}</span>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.params.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BTable, BPagination, VBTooltip, BFormGroup, BFormInput, BAvatar, BImg, BOverlay, BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import request from '@/libs/request/index'
import { formatDateLanguages, decimales } from '@/libs/tools/helpers'

const defaultFilters = {
  meterNumber: '',
  serie: '',
  serieCard: '',
  installed: '',
  transmissionStatus: '',
  contract: ''
}

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BCardHeader,
    BFormGroup,
    BAvatar,
    BImg,
    BOverlay,
    BFormInput,
    BFormSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      show: false,
      loading: false,
      tableColumns: [
        { key: 'contract', label: 'Contrato', sortable: true },
        { key: 'meterNumber', label: 'Número', sortable: true },
        { key: 'serie', label: 'Serie Spore', sortable: true },
        { key: 'serieCard', label: 'Serie de tarjeta', sortable: true },
        { key: 'energy', label: 'Energía', sortable: true },
        { key: 'status', label: 'Estatus Comunicación', sortable: true },
        { key: 'installedAt', label: 'Estado Instalación Medidor', sortable: true },
        { key: 'trasmitedAt', label: 'Última lectura', sortable: true },
        { key: 'relayState', label: 'Relevador', sortable: true }
      ],
      totalRows: 0,
      currentPage: 1,
      count: 0,
      perPageOptions: [10, 25, 50, 100],
      transmitionStatusOptions: [
        { value: '', text: 'Todos' },
        { value: '0', text: 'Transmitiendo' },
        { value: '1', text: 'Más de un día' },
        { value: '2', text: 'Sin transmitir' }
      ],
      response: [],
      responseExport: [],
      params: {
        limit: 20,
        offset: 0,
        byColumn: 'trasmitedAt',
        byOrder: 'desc',
        divisionUuid: this.$store.state.session.AppActiveUser().division.uuid
      },
      filters: {
        ...defaultFilters
      },
      urlDownload: ''
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    formValidateDate (date) {
      if (date === null) return '0'

      const date1 = new Date()
      const date2 = new Date(date)
      const diffTime = Math.abs(date2 - date1)

      let status = 2
      if (diffTime <= 86400000) {
        status = 1
      } else {
        status = 2
      }

      return status
    },
    calculateTransmissionStatusIndicator (status) {
      return {
        color: status === 0 ? 'success' : status === 1 ? 'warning' : 'secondary',
        message: status === 0 ? 'Transmitiendo' : status === 1 ? 'Más de un día' : 'Sin transmitir',
        icon: status === 0 ? 'ZapIcon' : 'ZapOffIcon'
      }
    },
    async getResponse () {
      const filtersNotEmpty = Object.fromEntries(Object.entries(this.filters).filter(([_key, value]) => value !== ''))
      request({
        url: '/meters079',
        method: 'GET',
        params: { ...this.params, filters: JSON.stringify(filtersNotEmpty) }
      }).then(res => {
        this.response = res.data.data.rows.map(meter => ({
          uuid: meter.uuid,
          meterNumber: meter.meterNumber,
          serie: meter.serie,
          serieCard: meter.serieCard,
          contract: meter.contract ?? '',
          energy: meter.energy ?? '0',
          transmissionStatusIndicator: this.calculateTransmissionStatusIndicator(meter.transmissionStatus),
          trasmitedAt: meter.trasmitedAt,
          installedAt: meter.installedAt ? 'Instalado' : 'No instalado'
        }))

        this.totalRows = res.data.data.count
        this.count = res.data.data.rows.length
      })
    },
    showFilter () {
      this.show = !this.show
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    detail (uuid) {
      this.$router.push(`/meters/GWH0079/${uuid}/detail`)
    },
    goToInstallMeter () {
      this.$router.push('/meters/GWH0079/install')
    },
    goToRegisterMeter () {
      this.$router.push('/meters/GWH0079/register')
    },
    search () {
      this.params.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    clear () {
      this.params.offset = 0
      this.currentPage = 1
      this.filters = { ...defaultFilters }
      this.getResponse()
    },
    async getMetersExport () {
      const filtersNotEmpty = Object.fromEntries(Object.entries(this.filters).filter(([_key, value]) => value !== ''))
      request({
        url: '/meters079/export',
        method: 'GET',
        params: { divisionUuid: this.$store.state.session.AppActiveUser().division.uuid, filters: JSON.stringify(filtersNotEmpty) }
      }).then(res => {
        this.responseExport = res.data.data.rows
        this.createCsv()
      })
    },
    createCsv () {
      const headers = [
        'Numero Contrato',
        'Numero Medidor',
        'Modelo',
        'Lote',
        'Serie Spore',
        'Serie Tarjeta',
        'Energia (KWh)',
        'Estatus Comunicacion',
        'Estatus Instalacion del Medidor',
        'Relevador',
        'Fecha de Ultima Lectura',
        'Hora de Ultima Lectura'
      ]

      const csv = [headers.join(',')]

      for (let i = 0; i < this.responseExport.length; i++) {
        const meter = this.responseExport[i]
        const [, model, block] = meter.meterInstalled ? meter.meterInstalled.split(' ') : ''

        const communicationStatus = this.calculateTransmissionStatusIndicator(meter.transmissionStatus).message
        const installationStatus = meter.installedAt ? 'Instalado' : 'No instalado'
        const transmissionDate = meter.trasmitedAt ? new Date(meter.trasmitedAt).toLocaleDateString() : undefined
        const transmissionTime = meter.trasmitedAt ? new Date(meter.trasmitedAt).toLocaleTimeString() : undefined
        const row = []

        row.push(meter.contract)
        row.push(meter.meterNumber, model, block)
        row.push(meter.serieCard, meter.serie) // números de serie
        row.push(meter.energy)
        row.push(communicationStatus)
        row.push(installationStatus)
        row.push(meter.relayState === '50' ? 'Abierto' : 'Cerrado')
        row.push(transmissionDate)
        row.push(transmissionTime)
        csv.push(row.join(','))
      }
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)

      const descarga = document.createElement('a')
      descarga.href = this.urlDownload
      descarga.target = '_blank'
      descarga.download = 'Listado_Medidores.csv'
      descarga.click()
    },
    textTransform (val, name) {
      this.filters[name] = val.toUpperCase()
    },
    handleSort ({ sortBy, sortDesc }) {
      if (sortBy === 'status') sortBy = 'trasmitedAt'
      this.params.byColumn = sortBy
      this.params.byOrder = sortDesc ? 'desc' : 'asc'
      this.getResponse()
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.params.offset = (val - 1) * this.params.limit
      this.getResponse()
    },
    byColumn (val) {
      this.params.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
